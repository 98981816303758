export const DUMMY_DATA=[
    {
        "id": "298",
        "client_id": "359",
        "title": "asdvww",
        "description": "<p>asd</p>",
        "category": null,
        "experience": null,
        "job_type": "remote",
        "contract_type": "part-time",
        "job_location": "Albania",
        "engagement_type": null,
        "project_length": null,
        "development_should_start_in": null,
        "is_meeting_scheduled": false,
        "meeting_date": null,
        "meeting_start_time": null,
        "meeting_end_time": null,
        "skills": "Nuxt.js",
        "optional_skills": "Nest.js",
        "status": "published",
        "deleted_at": null,
        "qualification_filter_out": false,
        "step_completed": 3,
        "created_at": "2024-06-21T13:04:07.388Z",
        "updated_at": "2024-06-21T13:06:04.825Z",
        "screening_questions": [
            {
                "id": 128,
                "job_id": 298,
                "title": "",
                "question": "How many years of experience do you currently have?",
                "ideal_answer": "1",
                "must_have": false,
                "created_at": "2024-06-21T13:06:04.806Z",
                "updated_at": "2024-06-21T13:06:04.806Z",
                "question_type": ""
            },
            {
                "id": 130,
                "job_id": 298,
                "title": "swedish",
                "question": "What is your level of proficiency in [Language]?",
                "ideal_answer": "conversational",
                "must_have": false,
                "created_at": "2024-06-21T13:06:04.806Z",
                "updated_at": "2024-06-21T13:06:04.806Z",
                "question_type": "language"
            },
            {
                "id": 129,
                "job_id": 298,
                "title": "English",
                "question": "Have you completed the following level of education: [Degree]",
                "ideal_answer": "Yes",
                "must_have": false,
                "created_at": "2024-06-21T13:06:04.806Z",
                "updated_at": "2024-06-21T13:06:04.806Z",
                "question_type": "Degree"
            }
        ],
        "client": {
            "id": "359",
            "name": " Admin Test",
            "email": "rexett_admin@yopmail.com",
            "profile_picture": "https://app.journalfy.co/trip_entry_photos/1707224671--ambience5.jpg",
            "status": "active",
            "company_name": null,
            "company_logo": null
        },
        "job_category": null
    },
]



export const EMPLOYEE_ROLE=[
    { label: "Workspace Admin", value: "workspace_admin" },
    { label: "Admin", value: "admin" },
    { label: "Support Assistance", value: "support_assistance" },
    { label: "Maintainance", value: "maintainance" },
    { label: "Accountant", value: "accountant" },
    { label: "HR", value: "hr" },
    { label: "Assistance", value: "assistance" },
  ]
export const TIME_REPORTING=[
    {
        "contractDetails": {
            "contract_id": "37",
            "client_id": "655",
            "developer_id": "657",
            "employment_type": "full-time",
            "job_type": "Not Specified",
            "status": true,
            "user_details": {
                "id": "657",
                "name": "Pankaj Pundir",
                "email": "pankajdev@yopmail.com",
                "profile_picture": null
            },
            "remarks": [
                {
                    "id": 95,
                    "contract_id": 37,
                    "remark": "kalal111futher is not done",
                    "added_by": 657,
                    "client_remark": null,
                    "created_at": "2024-06-05T07:35:48.362Z",
                    "updated_at": "2024-06-05T07:41:11.376Z",
                    "user": {
                        "id": "657",
                        "name": "Pankaj Pundir",
                        "email": "pankajdev@yopmail.com",
                        "profile_picture": null,
                        "role": "developer"
                    }
                },
                {
                    "id": 96,
                    "contract_id": 37,
                    "remark": "asdasdasd",
                    "added_by": 657,
                    "client_remark": null,
                    "created_at": "2024-06-05T07:42:15.335Z",
                    "updated_at": "2024-06-05T07:42:15.335Z",
                    "user": {
                        "id": "657",
                        "name": "Pankaj Pundir",
                        "email": "pankajdev@yopmail.com",
                        "profile_picture": null,
                        "role": "developer"
                    }
                },
                {
                    "id": 97,
                    "contract_id": 37,
                    "remark": "qqqqWokred on submit timed rpoeritng system",
                    "added_by": 657,
                    "client_remark": null,
                    "created_at": "2024-06-05T09:56:02.243Z",
                    "updated_at": "2024-06-05T09:56:02.243Z",
                    "user": {
                        "id": "657",
                        "name": "Pankaj Pundir",
                        "email": "pankajdev@yopmail.com",
                        "profile_picture": null,
                        "role": "developer"
                    }
                },
                {
                    "id": 98,
                    "contract_id": 37,
                    "remark": "futher is not done",
                    "added_by": 657,
                    "client_remark": "",
                    "created_at": "2024-06-05T11:11:22.345Z",
                    "updated_at": "2024-06-05T11:11:22.345Z",
                    "user": {
                        "id": "657",
                        "name": "Pankaj Pundir",
                        "email": "pankajdev@yopmail.com",
                        "profile_picture": null,
                        "role": "developer"
                    }
                }
            ]
        },
        "timeReports": [
            {
                "report_date": "2024-06-23",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            },
            {
                "report_date": "2024-06-24",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-25",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-26",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-27",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-28",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-29",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            }
        ],
        "startDate": "2024-06-23",
        "endDate": "2024-06-29",
        "totalDuration": 0,
        "is_complete": false,
        "isApproved": false
    },
    {
        "contractDetails": {
            "contract_id": "39",
            "client_id": "655",
            "developer_id": "685",
            "employment_type": "full-time",
            "job_type": "remote",
            "status": true,
            "user_details": {
                "id": "685",
                "name": "jonex",
                "email": "Jonex@yopmail.com",
                "profile_picture": "https://rexett-dev.s3.amazonaws.com/images/image%20%2812%29.png"
            },
            "remarks": []
        },
        "timeReports": [
            {
                "report_date": "2024-06-23",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            },
            {
                "report_date": "2024-06-24",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-25",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-26",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-27",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-28",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-29",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            }
        ],
        "startDate": "2024-06-23",
        "endDate": "2024-06-29",
        "totalDuration": 0,
        "is_complete": false,
        "isApproved": false
    },
    {
        "contractDetails": {
            "contract_id": "40",
            "client_id": "655",
            "developer_id": "427",
            "employment_type": "full-time",
            "job_type": "hybrid",
            "status": true,
            "user_details": {
                "id": "427",
                "name": "Rexett dev",
                "email": "dev135@yopmail.com",
                "profile_picture": null
            },
            "remarks": []
        },
        "timeReports": [
            {
                "report_date": "2024-06-23",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            },
            {
                "report_date": "2024-06-24",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-25",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-26",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-27",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-28",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": false,
                "leave_type": null,
                "leaveStartTime": null,
                "leaveEndTime": null,
                "is_public_holiday": false,
                "holiday_name": null
            },
            {
                "report_date": "2024-06-29",
                "is_off_day": true,
                "start_time": null,
                "end_time": null,
                "duration": null,
                "submitted_by_developer": false,
                "approved_by_client": false,
                "reconciliation_approved": null,
                "memo": null,
                "client_remark": null,
                "is_holiday": true,
                "leave_type": "Weekly Off",
                "is_public_holiday": false,
                "holiday_name": "Holiday"
            }
        ],
        "startDate": "2024-06-23",
        "endDate": "2024-06-29",
        "totalDuration": 0,
        "is_complete": false,
        "isApproved": false
    }
]


export const VIDEO_MEETING=[{label:"Rexett video meeting",value:"rexet_video_meeting"},{label:"Google meet",value:"google_meet"},{label:"Microsoft team",value:"microsoft_team"}]