export const JOB_LIST = [
  {
    id: "54",
    client_id: "359",
    title: "Software Engineer",
    description: "Develop and maintain web applications",
    category: null,
    experience: null,
    job_type: "hybrid",
    contract_type: "full-time",
    job_location: "New York",
    engagement_type: null,
    project_length: null,
    development_should_start_in: null,
    is_meeting_scheduled: false,
    meeting_date: null,
    meeting_start_time: null,
    meeting_end_time: null,
    skills: "JavaScript, React, Node.js",
    optional_skills: "Docker, Kubernetes",
    status: "published",
    deleted_at: null,
    qualification_filter_out: false,
    step_completed: 3,
    created_at: "2024-09-06T09:50:54.598Z",
    updated_at: "2024-09-06T09:51:20.487Z",
    job_positions: "3",
    time_zone: "UTC",
    response_date: "2024-09-06T00:00:00.000Z",
    job_external_id: "7f6b2b46-be24-4d86-bf7f-d203dd8c2508",
    job_skills: [
      {
        job_id: 54,
        skill_id: 137,
        id: 20,
        skill_name: "HTML",
        weight: "Intermediate",
        createdAt: "2024-09-05T09:18:58.655Z",
        updatedAt: "2024-09-05T09:18:58.655Z",
      },
      {
        job_id: 54,
        skill_id: 133,
        id: 19,
        skill_name: "Nest.js",
        weight: "Intermediate",
        createdAt: "2024-09-06T09:51:13.782Z",
        updatedAt: "2024-09-06T09:51:13.782Z",
      },
      {
        job_id: 54,
        skill_id: 132,
        id: 11,
        skill_name: "Ruby on Rails",
        weight: "Expert",
        createdAt: "2024-09-06T09:51:13.782Z",
        updatedAt: "2024-09-06T09:51:13.782Z",
      },
      {
        job_id: 54,
        skill_id: 121,
        id: 10,
        skill_name: "JavaScript",
        weight: "Expert",
        createdAt: "2024-09-06T09:51:13.782Z",
        updatedAt: "2024-09-06T09:51:13.782Z",
      },
    ],
    screening_questions: [
      {
        id: 40,
        job_id: 54,
        title: "Degree  Name",
        question:
          "Have you completed the following level of education: [Degree]?",
        ideal_answer: "Bachelor's Degree",
        must_have: true,
        created_at: "2024-09-06T09:51:20.482Z",
        updated_at: "2024-09-06T09:51:20.482Z",
        question_type: "Degree, Skill",
        response_type: "yes_no",
      },
    ],
  },
  {
    id: "59",
    client_id: "359",
    title: "need ruby and rails developer ",
    description:
      "<ol><li><strong>Check your build tool (like Webpack or Babel)</strong>: If you're using a bundler like Webpack or Babel, make sure it's configured to handle this new syntax. If it's not, you may need to update your bundler or its configuration to support import assertions.</li></ol><p>After making this change, your JSON should be properly imported, and the error should be resolved. Let me know if you need more guidance!</p>",
    category: null,
    experience: null,
    job_type: "hybrid",
    contract_type: "full-time",
    job_location: "carporate",
    engagement_type: null,
    project_length: null,
    development_should_start_in: null,
    is_meeting_scheduled: false,
    meeting_date: null,
    meeting_start_time: null,
    meeting_end_time: null,
    skills: "Ruby on Rails",
    optional_skills: "JavaScript",
    status: "published",
    deleted_at: null,
    qualification_filter_out: false,
    step_completed: 3,
    created_at: "2024-09-10T12:53:58.069Z",
    updated_at: "2024-09-10T12:54:42.191Z",
    job_positions: "12",
    time_zone: "Europe/Tirane (+02:00)",
    response_date: "2024-09-12T00:00:00.000Z",
    job_external_id: "c4812ad3-1734-402c-9251-fdabc57f5859",
    job_skills: [
      {
        job_id: 59,
        skill_id: 121,
        id: 18,
        skill_name: "JavaScript",
        weight: "Expert",
        createdAt: "2024-09-10T12:54:30.588Z",
        updatedAt: "2024-09-10T12:54:30.588Z",
      },
      {
        job_id: 59,
        skill_id: 131,
        id: 17,
        skill_name: "Ruby on Rails",
        weight: "Intermediate",
        createdAt: "2024-09-10T12:54:30.588Z",
        updatedAt: "2024-09-10T12:54:30.588Z",
      },
    ],
    screening_questions: [
      {
        id: 47,
        job_id: 59,
        title: "",
        question: "How many years of experience do you currently have?",
        ideal_answer: "1",
        must_have: false,
        created_at: "2024-09-10T12:54:42.185Z",
        updated_at: "2024-09-10T12:54:42.185Z",
        question_type: "",
        response_type: null,
      },
      {
        id: 48,
        job_id: 59,
        title: "Bachelor of Science",
        question:
          "Have you completed the following level of education: [Degree]",
        ideal_answer: "Yes",
        must_have: false,
        created_at: "2024-09-10T12:54:42.187Z",
        updated_at: "2024-09-10T12:54:42.187Z",
        question_type: "Degree",
        response_type: null,
      },
      {
        id: 49,
        job_id: 59,
        title: "English",
        question: "What is your level of proficiency in [Language]?",
        ideal_answer: "conversational",
        must_have: false,
        created_at: "2024-09-10T12:54:42.189Z",
        updated_at: "2024-09-10T12:54:42.189Z",
        question_type: "language",
        response_type: null,
      },
    ],
  },
];
